<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            outlined
            label="Tingkat"
            v-model="selTingkat"
            :options="tingkat"
            @input="getListMapel()"
            style="min-width: 150px;"
          ></q-select>
          <q-select
            use-input
            @filter="filterFn"
            :options="filteredMapel"
            v-model="selMapel"
            outlined
            :disable="!selTingkat"
            label="Mata Pelajaran"
            style="min-width: 250px;"
            @input="getGurumapelList()"
          ></q-select>
          <q-btn
            :disable="selMapel == null"
            icon="add"
            color="primary"
            label="Tambah Guru Mapel"
            v-can="[]"
            @click="showDialogEdit(-1)"
          ></q-btn>
        </div>
        <q-input outlined label="Cari Kelas" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>No</th>
            <th>Nama Guru</th>
            <th>Nama Kelas</th>
            <th style="width:20px;" v-can="['KURIKULUM']"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-if="selMapel == null">
            <td class="text-center" colspan="4">
              Silahkan Pilih Mata Pelajaran Terlebih Dahulu
            </td>
          </tr>
          <tr v-else-if="ajar.length == 0">
            <td class="text-center" colspan="4">Belum ada data pengajar</td>
          </tr>
          <tr v-for="(val, i) in filAjar" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.guru }}</td>
            <td>{{ val.rombel }}</td>
            <td class="q-gutter-sm" v-can="['KURIKULUM']">
              <q-btn
                flat
                dense
                icon="edit"
                color="secondary"
                @click="showDialogEdit(val.id)"
              ></q-btn>
              <q-btn
                flat
                dense
                @click="
                  (toDel = {
                    id: val.id,
                    guru: val.guru,
                    rombel: val.rombel,
                    mapel: selMapel.label,
                  }),
                    (confirm = true)
                "
                color="red"
                icon="delete_forever"
              />
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
    <q-dialog v-model="confirm" persistent>
      <q-card class="bg-negative text-white">
        <q-card-section class="row items-center">
          <strong class="q-ml-sm"
            >Apa anda yakin ingin menghapus mata pelajaran pengajaran "{{
              toDel.mapel
            }}" oleh "{{ toDel.guru }}" pada kelas "{{ toDel.rombel }}"
            ?</strong
          >
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Tidak" color="white" v-close-popup />
          <q-btn
            flat
            label="Ya"
            color="white"
            @click="deleteData(toDel.id)"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogEditGurumapel from "@/components/DialogEditGurumapel";
export default {
  data() {
    return {
      toDel: {
        id: 0,
        guru: "",
        rombel: "",
        mapel: "",
      },
      confirm: false,
      searchTerm: "",
      selMapel: null,
      mapel: [],
      filteredMapel: [],
      ajar: [],

      tingkat: [],
      selTingkat: null,
    };
  },
  computed: {
    filAjar() {
      if (this.searchTerm == "") return this.ajar;
      else {
        return this.ajar.filter((val) => {
          let cNama =
            val.rombel.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !=
            -1;
          return cNama;
        });
      }
    },
  },
  async mounted() {
    await this.getTingkat();
  },
  methods: {
    async getTingkat() {
      let resp = await this.$http.get(
        `/penilaian/input_ekskul/gettingkat/${localStorage.getItem("jenjang")}`
      );
      let tingkat = [];
      for (let item of resp.data) {
        tingkat.push(item.tingkat);
      }
      this.tingkat = tingkat;
    },
    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.filteredMapel = this.mapel;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredMapel = this.mapel.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    deleteData(id) {
      this.$http.put("/pengaturan/gurumapel/delajar/" + id).then((resp) => {
        this.getGurumapelList();
      });
    },
    getGurumapelList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http
        .get("/pengaturan/gurumapel/getlistajar/" + this.selMapel.value)
        .then((resp) => {
          this.ajar = resp.data;
        });
      this.$q.loading.hide();
    },
    async getListMapel() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengaturan/gurumapel/getlistmapel/${localStorage.getItem(
          "jenjang"
        )}/${this.selTingkat}`
      );

      this.mapel = resp.data;
      this.$q.loading.hide();
    },
    showDialogEdit(id) {
      this.$q
        .dialog({
          component: DialogEditGurumapel,
          parent: this,
          idAjar: id,
          idMapel: this.selMapel.value,
        })
        .onOk((resp) => {
          this.selMapel.value = resp.value;
          this.selMapel.label = resp.label;
          this.getGurumapelList();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
