<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-sm">
        <q-input
          outlined
          disable
          v-model="bundleAjar.mapel"
          label="Nama Mapel"
        ></q-input>
        <q-select
          use-input
          @filter="filterFnGuru"
          :options="filteredGuru"
          v-model="bundleAjar.guru"
          outlined
          label="Guru"
        ></q-select>
        <q-select
          use-input
          @filter="filterFnKelas"
          :options="filteredRombel"
          v-model="bundleAjar.rombel"
          outlined
          label="Rombongan Belajar"
        ></q-select>
      </q-card-section>
      <q-card-actions class="row justify-end">
        <q-btn outline color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          v-if="idAjar < 0"
          color="primary"
          @click="cekAjar"
          :disable="bundleAjar.guru == null || bundleAjar.rombel == null"
          >Tambah</q-btn
        >
        <q-btn v-else color="primary" @click="edit">Edit</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["idAjar", "idMapel"],
  data() {
    return {
      guru: [],
      filteredGuru: [],
      rombel: [],
      filteredRombel: [],
      bundleAjar: {
        rombel: null,
        guru: null,
        mapel: null,
      },
    };
  },
  async mounted() {
    if (this.idAjar > 0) {
      await this.getAjarDetail();
    } else {
      this.bundleAjar.mapel = this.idMapel;
    }

    await this.getMapel();
    await this.getListGuru();
    await this.getListRombel();
  },
  methods: {
    filterFnKelas(val, update) {
      if (val === "") {
        update(() => {
          this.filteredRombel = this.rombel;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredRombel = this.rombel.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    filterFnGuru(val, update) {
      if (val === "") {
        update(() => {
          this.filteredGuru = this.guru;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredGuru = this.guru.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    async getAjarDetail() {
      await this.$http
        .get("/pengaturan/gurumapel/getajardetail/" + this.idAjar)
        .then((resp) => {
          this.bundleAjar = resp.data;
        });
      await this.$http
        .get("/pengaturan/gurumapel/getajarrombel/" + this.bundleAjar.rombel)
        .then((resp) => {
          this.bundleAjar.rombel = resp.data;
        });
      await this.$http
        .get("/pengaturan/gurumapel/getajarguru/" + this.bundleAjar.guru)
        .then((resp) => {
          this.bundleAjar.guru = resp.data;
        });
    },
    edit() {
      this.bundleAjar.guru = this.bundleAjar.guru.value;
      this.bundleAjar.rombel = this.bundleAjar.rombel.value;

      this.$http
        .put("/pengaturan/gurumapel/update/" + this.idAjar, this.bundleAjar)
        .then((resp) => {
          this.onOKClick();
        });
    },
    createAjar() {
      this.bundleAjar.rombel = this.bundleAjar.rombel.value;
      this.bundleAjar.guru = this.bundleAjar.guru.value;
      this.$http
        .post("/pengaturan/gurumapel/tambah/" + this.idMapel, this.bundleAjar)
        .then((resp) => {
          this.onOKClick();
        });
    },
    async cekAjar() {
      await this.$http
        .get(
          `/pengaturan/gurumapel/cekajar/${this.idMapel}/${this.bundleAjar.rombel.value}`
        )
        .then((resp) => {
          if (resp.data.length == 0) {
            this.createAjar();
          } else {
            this.$q.notify({
              message: `Sudah ada pengajar di Kelas ${this.bundleAjar.rombel.label} di mapel terpilih!`,
              color: "negative",
            });
          }
        });
    },
    async getMapel() {
      await this.$http
        .get("/pengaturan/gurumapel/getmapel/" + this.idMapel)
        .then((resp) => {
          this.bundleAjar.mapel = resp.data.nama;
        });
    },
    async getListGuru() {
      await this.$http
        .get(
          "/pengaturan/gurumapel/getlistguru/" + localStorage.getItem("jenjang")
        )
        .then((resp) => {
          this.guru = resp.data;
        });
    },
    async getListRombel() {
      await this.$http
        .get(
          `/pengaturan/gurumapel/getlistrombel/${localStorage.getItem(
            "jenjang"
          )}/${this.idMapel}`
        )
        .then((resp) => {
          this.rombel = resp.data;
        });
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      let data = {
        value: this.idMapel,
        label: this.bundleAjar.mapel,
      };
      this.$emit("ok", data);
      this.hide();
    },
    onCancelClick() {
      this.hide();
    },
  },
};
</script>
